/**
 * Parses a date string and formats it into a human-readable string
 *
 * NOTE: we use a timezone to prevent problems with a server that are in a different timezone
 *
 * example input: 'Wed Nov 20 2024 00:00:00 GMT+0100 (Central European Standard Time)';
 * example output: "20 november 2024"
 */
export const parseDate = (dateString: string) => {
  const parsed = new Date(dateString).toLocaleDateString("nl", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    timeZone: "Europe/Amsterdam",
  });
  return parsed === "Invalid Date" ? "" : parsed;
};
